@import '../variables.scss';

.htmlTab {
  overflow: hidden;
}

.timerCode {
  overflow-y: scroll;
  resize: none;
  border-radius: 2px;
  width: 100%;
  height: 120px;
  margin-top: 10px;
}

.htmlCode {
  position: relative;
  width: inherit;
  margin-bottom: 30px;
  margin-top: 15px;

  button {
    float: right;
    background: $primary-color;
    border-radius: 4px;
    color: $white;
    border: none;
    padding: 6px 8px;
  }
}

.htmlDescription {
  font-style: italic;
}

.htmlOptions {
  display: flex;
  align-items: center;
}

.option {
  flex: 1;
  padding: 15px;  

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;  
  color: $black-dark;
}

.activeButton{
  background: $secondary-color !important;
  color:$white !important;
}

.unactiveButton{
  background: $grey-second !important;
  color:$grey-third !important;
}

.optionCheck{
  background-color: $secondary-color !important;
}