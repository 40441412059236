@import "../variables.scss";
.planDiv {
  display: flex;
  justify-content: center;
}
.planButtonDiv {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  border-radius: 10px;
  width: 219px;
  height: 46px;
  background-color: #eeeff2;
}
.selectedButton {
  background-color: #ffffff;
  box-shadow: 0px 4px 5px 0px #4e83fb5e;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #4e83fb;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
}
.notSelectedButton {
  font-family: Lato;
  color: #999999;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}
.planCard {
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 5px 0px #4e83fb5e;
  padding: 40px 20px 10px;
}
.heading1 {
  font-family: "Lato" !important;
  font-size: 38px !important;
  font-weight: 700 !important;
  line-height: 49px !important;
  letter-spacing: 0.05em !important;
  text-align: center;
}
.heading2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 5px;
  font-family: "Lato" !important;
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 34px !important;
  letter-spacing: 0em !important;
  text-align: center;  
}
.microHeading2{
  color: #EEEBDF !important;
}
.standardHeading2{
  color: #EF3A7B !important;
}
.powerHeading2{
  color: #1AC67D !important;
}
.price {
  font-size: 48px !important;
  line-height: 48px !important;
}
.font1 {
  font-family: "Lato" !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #aaaaaa;
}
.features {
  display: flex;
  justify-self: start;
  flex-direction: column;
}
.featuresFont {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Lato" !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left;
  margin-bottom: 10px;
}
.getStartedButton {  
  border-radius: 50px !important;
  background: #4e83fb !important;
  padding: 10px 30px !important;
  font-family: "Lato" !important;
  font-size: 21px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff !important;
}
.microStartedButton {
  border-radius: 50px !important;
  background: #EEEBDF !important;
  padding: 10px 30px !important;
  font-family: "Lato" !important;
  font-size: 21px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #000000 !important;
}
.standardStartedButton {
  border-radius: 50px !important;
  background: #EF3A7B !important;
  padding: 10px 30px !important;
  font-family: "Lato" !important;
  font-size: 21px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff !important;
}
.powerStartedButton {
  border-radius: 50px !important;
  background: #1AC67D !important;
  padding: 10px 30px !important;
  font-family: "Lato" !important;
  font-size: 21px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff !important;
}
.savePlanDiv {
  display: flex;
  justify-content: center;
  color: #09ac02;
  //styleName: subtext1;
  font-family: Lato;
  font-size: 23px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.05em;
  gap:10px;
  margin-top:10px;
  margin-bottom:10px;  
}
.saveOption {
  position: absolute;
  right: -20px;
  top: 10px;
}
.saveGreen {
  position: absolute;
  right: 0px;
  z-index: 10;
}
.saveText {
  position: absolute;
  right: 0px;
  z-index: 11;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  top: 4px;
  width: 85px;
}
.rowContainer{
  display:flex;
  gap:10px;
  justify-content: center;
  
  margin-top:5px;
  margin-bottom:5px;
}