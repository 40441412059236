@import "../variables.scss";
@import "../mixins.module.scss";

.navigation {
  background: $white;
  color: $gray-dark;
  position: relative;
  z-index: 10;
  .container {
    @include fluid-content;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 65px;
    padding-left: 0;
    padding-right: 0;
    width: 80% !important;
    max-width: 80% !important;
  }

  .logoSend {
    color: $gray-dark;
  }

  .logo {
    flex: 0 0 auto;
    margin-right: 80px;
  }

  ul {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-self: stretch;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 25px;
  }

  .loggedOutLinks {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      color: $gray-dark;
    }
  }

  .userProfileIcon {
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: $gray-dark;
    vertical-align: middle;
  }

  .userProfileText {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    color: $gray-dark;
  }

  .nav_active {
    position: relative;
  }

  .nav_active::after {
    content: "";
    text-decoration: underline;
    width: 100%;
    position: absolute;
    height: 2px;
    background: #4e84a1;
    left: 0;
    bottom: 0;
  }
}
