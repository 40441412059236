@import "../variables.scss";
.uploadLabel {
  display: inline-block;
  position: relative;
}

.trash {
  position: absolute;
  right: 0;
  color: $red-expired;
  cursor: pointer;
  font-size: 15px;
}