@import "../variables.scss";
.editorContainer {
  display: flex;
  height: 100%;
}



.editorOptions {
  flex: 0 0 490px;
  padding: 15px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.editorTimer {
  flex: 1;
  overflow-y: scroll;
  position: relative;
}

.editorActions {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid $separator;
  background-color: #ffffff;    
}

.editorButtons {
  margin-left: 20px;
}

.editorPreview {
  padding: 20px;
}

.preview {
  text-align: center;
  margin-bottom: 10px;
}

.template {
  display: flex;
}

.options {
  flex: 0 0 150px;

  .labels {
    input[type="text"] {
      width: 100px;
    }
  }
}

.digitHiddenButton {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

// .styleDropdownItem {
//   img {
//     max-height: 100%;
//     max-width: 100%;
//   }
// }

// .styleDropdownItem {
//   display: flex !important;
//   justify-content: center !important;
//   align-items: center !important;
//   width: 100%;
// }

// .ui.selection.dropdown .menu > .item {
//   display: flex !important;
//   justify-content: center !important;
//   align-items: center !important;
// }


// .ui.selection.dropdown .text {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }


/* Ensure dropdown items are centered */
/* Ensure dropdown items are centered */
.styleDropdown .menu > .item .styleDropdownItem {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
}

/* Ensure the selected item in the dropdown is centered */
.styleDropdown .text .styleDropdownItem {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
}

/* Additional specificity to ensure proper application */
.ui.selection.dropdown.styleDropdown .menu > .item .styleDropdownItem {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
}

.ui.selection.dropdown.styleDropdown .text .styleDropdownItem {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
}



.colors {
  display: flex;
}

.labels {
  margin-bottom: 5px;
}

// .sectionNew{
//   display: flex;
// }


.section {
  margin-bottom: 15px;

  h4 {
    i {
      padding-left: 10px;
      padding-right: 5px;
    }
  }
}

.buttonWrapper {
  display: inline-block;
}

.upgradeCtaContainer {
  margin-top: 3em;
}

.upgradeCta {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1.7em;
  font-weight: normal;
  margin-bottom: 0.5em;
}


.primaryButton{
  background-color: $primary-color !important;
  color:$white !important;
}

.cancelButton{
  background-color: $white !important;
  color:#222222 !important;
}

.activeButton{
  background: $secondary-color !important;
  color:$white !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.unactiveButton{
  background: $grey-second !important;
  color:$grey-third !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.endTimeStyle{
font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
color: #222222;
}


.modalActionButton {
    display: flex;
    justify-content: end;
    margin-top: 30px;
    gap: 15px;
}

.modalDescription h3{
  font-size: 1.4rem !important;
}


.modalDescription p{
  font-size: 1.1rem !important;
}