@import "../variables.scss";
.loginForm {
  width: 400px;
  margin: 50px auto;

  h1 {
    font-weight: 700;
    text-align: center;
  }
}

.forgotPassword {
  display: block;
  margin-bottom: 15px;
}