@import "../variables.scss";
.previewImage {
    background-image: /* tint image */
                      linear-gradient(to right, rgba(192, 192, 192, 0.75), rgba(192, 192, 192, 0.75)),
                      /* checkered effect */
                      linear-gradient(to right, black 50%, white 50%),
                      linear-gradient(to bottom, black 50%, white 50%);
    background-blend-mode: normal, difference, normal;
    background-size: 2em 2em;
    max-width: 100%;    
    height:100%;
}