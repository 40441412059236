@import "../variables.scss";
.row1{
    display: flex;
    justify-content: space-between;
}

.setupTitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #222222;
}
.linkStyle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #606062;
    cursor: pointer;
}
.rowTitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #222222;
}

.row2{
    margin-top:20px;
    display: flex;
    gap:42px;
    align-items: center;
}

.row3{
    margin-top:20px;
    display: flex;
    gap:16px;
}

.row3Description{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #606062;
    margin-top: 16px;
    margin-bottom: 24px;
}

.rowDescription{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #606062;
    margin-top: 16px;
    margin-bottom: 24px;
}

.row4{
    margin-top: 30px;
    display: flex;
    gap:16px;
}

.row5{
    margin-top: 30px;
    display: flex;
    gap:16px;
}