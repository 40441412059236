@import "../variables.scss";
.outer {
  background: #f8fcff;
  border-radius: 20px;
  padding: 80px;
  display: flex;
  gap: 20px;
}
.left {
  flex: 1;
}
.right {
  flex: 1;
}
.features {
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
}
.headingFont {
  font-style: normal;
  font-weight: 700;
  font-size: 38px !important;
  line-height: 49px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
}
.font1 {
  font-style: normal;
  font-weight: 400;
  font-size: 23px !important;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;
}
.featureRow {
  display: flex;
  justify-content: space-between;
}
.pointCol {
  display: flex;
  gap: 10px;
  flex: 1;
  margin-bottom: 8px;
}
.font2 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px !important;
  line-height: 20px;
  letter-spacing: 0.05em;

  color: #000000;
}
.font3 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px !important;
  line-height: 27px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #000000;
}
.font4 {
  font-style: normal;
  font-weight: 400;
  font-size: 23px !important;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
}
.priceDiv {
  background: #4e83fb;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  padding: 10px;
}
.largeFont {
  font-style: normal;
  font-weight: 700;
  font-size: 45px !important;
  line-height: 58px;
  letter-spacing: 0.05em;
  text-transform: lowercase;

  color: #ffffff;
}
.smallFont {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.05em;
  text-transform: lowercase;

  color: #ffffff;
}
.dropFont1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px !important;
  line-height: 31px;
  letter-spacing: 0.05em;
  text-transform: capitalize;

  color: #000000;
}
.dropFont2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-transform: lowercase;
  color: #000000;
}
.planDiv {
  position: absolute;
  background: black;
  width: 390px;
  border-radius: 10px;
  top: 0px;
  right: -400px;
  color: white;
}
.planList {
  cursor: pointer;
  margin: 20px 0;
  padding: 0;
  list-style: none;
}
.planList li {
  padding-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: 0.05em;
}
.planList li.selected {
  background: #4e83fb;
}
.choosePlanButton {
  background: #000000 !important;
  border-radius: 50px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 27px !important;
  text-align: center;
  margin-top: 30px !important;

  color: #ffffff !important;
}
.additionalDiv {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;

  color: #000000;
  background: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px;
}

/* For Smaller device */
@media only screen and (max-width: 1680px) {
  .planDiv {
    width: 275px;
    border-radius: 5px;
    right: -270px;    
  }
  .planList li {
    font-size: 16px;
    line-height:25px;
    letter-spacing: 0.05em;
  }  
}
/* For Smaller device */
@media only screen and (max-width: 1680px) {
  .planDiv {
    right:-200px;
  }
}
