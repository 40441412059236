@import '../variables.scss';

.name {
  flex: 4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview {
  flex: 0 0 25px;
}

.date {
  flex: 1;
}

.views {
  flex: 1;
}

.status {
  flex: 0 0 45px;
  text-align: center;
}

.actions {
  flex: 0 0 100px;
  text-align: center;

  i {
    font-size: 18px;
    padding: 0 5px;
  }
}

.expired {
  color: $gray-disabled;
}

.buttonWrapper {
  display: inline-block;
}

.primaryButton{
  background-color: $primary-color !important;
  color:$white !important;
}
