$white: #fff;
$black: #000;
$gray-dark: #222;
$gray-medium: #282828;
$gray-light: #3f3f3f;
$gray-disabled: #8f8f8f;
$gray-subheader: #b5b5b5;
$blue: #1ac6ff;
$footer: #ababab;
$separator: #eaeaea;
$red-expired: #ff392a;
$red-warning: #db2828;
$primary-color:#5DC2FA;
$secondary-color:#4E84A1;
$black-color:#222222;
$grey-bg:#F7F8F8;
$grey-second:#DCE2E3;
$grey-third:#606062;
$black-color:#222222;
$error-color:#B53131;
$black-dark:#000000;
$tick-icon:#286FF9;
