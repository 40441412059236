@import '../variables.scss';
.card {
  text-align: center;
}

.inactiveCta {
  font-size: 1.7em;
  font-weight: normal;
  display: inline;
  padding-right: 0.5em;
}

.inactiveCtaContainer {
  margin: 3em 0;
}


.card_background{
  background: $grey-bg !important;
  border-radius: 5px;
  text-align: center;
}

.seeAllButton{
  background-color: $white !important;
  color:$primary-color !important;
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;  
}