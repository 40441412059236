
@import '../variables.scss';


.digitHiddenButton{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.activeButton{
  background: $secondary-color !important;
  color:$white !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.unactiveButton{
  background: $grey-second !important;
  color:$grey-third !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}