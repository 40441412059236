@import "../variables.scss";
.title {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.description {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #939699;
}
.infoDiv {
  display: flex;
  padding: 12px;
  background-color: #f7f8f8;
  gap: 8px;
  border-radius: 4px;
}
.info {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #606062;
}
.upgradeDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.upgradeLink {
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #4e84a1;
  border-bottom: 1px solid #4e84a1;
  cursor: pointer;
}
.addButton {
  width: 100%;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #606062;
}
