// @import '../variables.scss';

// .timerTypeButton {
//   padding-left: 0 !important;
//   padding-right: 0 !important;
// }

// .activeButton {
//   background: rgb(101, 150, 234) !important;
//   color: $white !important;
//   padding: 10px 12px !important; /* Adjust padding as needed */
//   border-radius: 8px; /* Rounded corners */
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
//   transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition */
//   width: 100%; /* Full width */
//   margin-bottom: 14px; /* Space between buttons */
// }

// .unactiveButton {
//   background: $grey-second !important;
//   color: $grey-third !important;
//   padding: 10px 12px !important; /* Adjust padding as needed */
//   border-radius: 8px; /* Rounded corners */
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
//   transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition */
//   width: 100%; /* Full width */
//   margin-bottom: 14px; /* Space between buttons */
// }

// /* Container for stacking buttons */
// .button-stack {
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   width: 100%; /* Full width */
// }

// /* Add hover effects */
// button.activeButton:hover,
// button.unactiveButton:hover {
//   background-color: rgb(47, 115, 233) !important; /* Darken on hover */
//   box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
//   cursor: pointer; /* Pointer cursor on hover */
//   color: white !important;
// }


@import '../variables.scss';

.timerTypeButton {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 2px !important;
  
}


.activeButton {
  background: linear-gradient(135deg, rgb(90, 146, 241) 0%, rgb(70, 126, 221) 100%) !important; 
  color: $white !important;
  padding: 12px 15px !important; /* Adjust padding as needed */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  transition: background 0.3s, box-shadow 0.3s, transform 0.3s; /* Smooth transition */
  margin-bottom: 5px !important; /* Space between buttons */
  font-weight: bold; /* Bold text */
  font-size: 1rem; /* Slightly larger text */
}

.unactiveButton {
  background: linear-gradient(135deg, $grey-second 0%) !important;
  // color: $grey-fourth !important;
  padding: 12px 15px !important; /* Adjust padding as needed */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background 0.3s, box-shadow 0.3s, transform 0.3s; /* Smooth transition */
  margin-bottom: 5px !important; /* Space between buttons */
  font-weight: bold; /* Bold text */
  font-size: 1rem; /* Slightly larger text */
  transition: 0.6s ease-in-out !important;
}

/* Container for stacking buttons */
.button-stack {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; /* Full width */
  margin-bottom: 2px !important;
 
}

/* Add hover effects */

button.unactiveButton:hover {
  background: #e0e1e2 !important; /* Gradient on hover */
  cursor: pointer; /* Pointer cursor on hover */
  color: #fff !important;
  box-shadow: inset 200px 0 0 0 rgb(90, 146, 241)!important; /* Enhance shadow on hover */
 /* Slight lift on hover */
}

/* Add active state effects */

button.unactiveButton:active {
  background: #e0e1e2 !important; 
  box-shadow:inset 400px 0 0 0 rgb(90, 146, 241)!important; /* Enhance shadow on hover */
}