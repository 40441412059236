@import "../variables.scss";
.planCard {
  > div {
    flex-grow: 0 !important;
    &:nth-child(2) {
      flex-grow: 1 !important;
    }
  }
}

.planFeature {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.disabledFeature {
  color: $gray-disabled;
}

.tickButtonColor{
  color:$tick-icon !important;
}

.primaryButtonBackColor{
  background-color: $primary-color !important;
}

.sideBackgroundColor{
  background-color: $secondary-color !important;
}