@import '../variables.scss';

.labels {
  margin-bottom: 5px;
}
.customSlider {
  width: 100%; /* Set the desired width of the slider */
  height: 300px; /* Set the desired height of the slider */
  overflow-y: auto; /* Enable vertical scrollbar if content exceeds height */
  margin-top: 15px;
}
.sliderContainer{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.sliderContainer::-webkit-scrollbar {
  width: 5px;
}
.sliderBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 56px;
  height: 48px;
  background: #F7F8F8;
  border-radius: 4px;
}
.codeStyle{
  height: 17px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #4E84A1;
}
.languageStyle{
  height: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}