@import "../variables.scss";
.navLink {
  display: inline-block;
  // margin: 0 14px;
  color: $gray-dark;
  height: 100%;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.3s all;
  &::before {
    display: inline-block;
    height: 100%;
    content: "";
    vertical-align: middle;
  }

  &.active {
    // background-color: $gray-light;
    border-bottom: 3px solid $primary-color;
  }
  &:hover {
    color: #4e84a1;
  }
}
