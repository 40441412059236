.name {
  flex: 4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview {
  flex: 0 0 25px;
}

.date {
  flex: 1;
}

.actions {
  flex: 0 0 100px;
  text-align: center;
}
.actions i {
  font-size: 18px;
  padding: 0 5px;
}

.primaryButton {
  background-color: #5DC2FA !important;
  color: #fff !important;
}

/*# sourceMappingURL=index.module.css.map */
