@import "../variables.scss";
.tableContainer {
  position: relative;
}

.tableActions {
  position: absolute;
  top: 0;
  right: 0;
}

.name {
  i {
    margin-left: 10px !important;
  }
}

.actions {
  i {
    padding: 0 5px;
    color: $gray-light;

    &[disabled] {
      color: $gray-disabled;
    }

    &:not([disabled]) {
      cursor: pointer;
    }

    &:hover:not([disabled]) {
      color: $blue;
    }
  }
}

.zero {
  padding: 40px;
  text-align: center;
}

.pointer{
  cursor: pointer;
}

.secondary{
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display:block;
}

.tableBorder{
  border:1px solid $grey-second !important;
  border-radius: 5px !important;
}

.tableRowBackground{
  background-color: $grey-bg;
  height:24px;
}
.tableCol{
  padding-left:  0.785714em;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px;
  color:$grey-third;  
}

.actionDiv{
  display:flex;
  align-items: center;
  gap:13.5px;
}

.filterDiv{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.linkIcon{
  color: $secondary-color;
  cursor: pointer;
}

.primaryButton{
  background-color: $primary-color !important;
  color:$white !important;
}
.crossButton{
  background-color: $white !important;  
  padding:0px !important;
  width: 20px;
}

.inviteUserButton{
  background-color: $white !important;
  border: 1px solid $grey-second !important;
  border-radius: 7px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}


.seeAllButton{
  background-color: $white !important;
  color:$primary-color !important;
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;  
}

.cancelSubscriptionButton{
  background-color: $white !important;
  color:$secondary-color !important;
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;  
}

.heading2{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
}

.buttonContainer{
  display: flex;
  justify-content: center;
  gap:12px;
  padding-top:20px;
  // background-color: $white !important;
}

.font1{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.headerFont{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: $black-dark;
  text-align:center;
  border-bottom: none;
}

.errorFont1{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: $error-color;
}

.inputText{
  border:1px solid $primary-color;
  border-radius: 5px;
  padding:10px;
  width:300px;
}

.userInputText{
  border: 1px solid #E8EAEC;
  border-radius: 4px;
  padding:16px 14px;
  width:100%;
}


.errorInputText{
  border:1px solid $error-color;
  border-radius: 5px;
  padding:10px;
  width:300px;
}

input.inputText:focus, input.userInputText{  
  outline:none;
}

.errorFont2{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #B53131;
}

.textSmall{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $grey-third;
}

.primaryLinkButton{
  background-color: $primary-color !important;
  color:$white !important;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.renewText{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 17px;
  color: $grey-third;
}