@import './variables.scss';
:global {
  //TODO: Figure out why this url import doesn't work
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,800|Lato:300|Dosis:300,600");
  @import "./vendor/dateTime.module.scss";
  @import "./transitions/fade.module.scss";
  // @import "../../../node_modules/react-select/dist/react-select";
  // @import "../../../node_modules/react-virtualized-select/styles";

  body {
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif;

    .home-layout {
      font-family: "Open Sans", sans-serif;
      color: #535353;
      font-weight: 300;
      font-size: 0.9375rem;
      line-height: 1.9;
      transition: margin-left 0.5s;

      s {
        opacity: 0.4;
      }
    }
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  button {
    i {
      margin-right: 5px;
    }
  }

  #g-recaptcha div {
    margin: 15px auto;
  }

  .separator {
    background: $white;
    color: $white;
    border-top: 1px solid $separator;
  }

  p {
    font-size: 1.2rem !important;
  }

  .sendtric-icon {
    color: $blue;
  }

  .copyright {
    font-size: 12px;
  }

  .topbar-nav {
    padding-right: 15px;
  }

  .ui.message .header {
    padding: 0;
  }

  .beta-tag {
    width: 50px;
    margin: 0 auto;
    cursor: default;
  }

  // Fixes react-virtualized-select styles broken by semantic-ui-react
  .Select-multi-value-wrapper input {
    border: none !important;
    padding-left: 0 !important;
  }

  .field .fa-info-circle,
  #pricing .fa-info-circle {
    margin-left: 5px;
    margin-right: 5px;
  }

  .badge {
    margin-left: 5px;
    font-size: 9px;
    border-radius: 2px;
    color: white;
    padding: 2px 3px;
    display: inline-block;
    vertical-align: text-bottom;
    line-height: 12px;
    cursor: default;
  }

  .badge-new {
    background: $blue;
  }

  .ui.pointing.secondary.menu .active.item {
    border-color: $secondary-color !important;
  }

  .ui.pagination {
    border: none;
    box-shadow: none;
    a.item {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  .ui.pagination.menu .item {
    border: none;
    box-shadow: none;
  }
  .ui.menu .item::before {
    content: none;
  }
  .ui.pagination.menu .active.item {
    border-radius: 4px;
  }

  .ui.progress .bar {
    min-width: 0px !important;
  }
  .ui.dimmer .ui.loader:before {
    border-color: #5dc2fa;
  }

  // .editor__section__2DMyj h4{
  // font-size: 18px !important;
  // color:#5dc2fa !important; 
  // }

  // .ui form editor__editorOptions__3t9Tk{
  //   width: 200px !important;
  // }

  .ui.form.editor__editorOptions__3t9Tk {
    -webkit-box-flex: 0;
    flex: 0 0 378px;
    padding: 15px;
    overflow-y: scroll;
}

.red.exclamation.circle.icon {
  margin-left: -3px; 
}


.editor__colors__2KG4q
{
  flex-direction: column;
}


.ui.toggle.checkbox {
  min-height: 2.5rem !important;
  position: relative;
  top: 5px;
}

// .colorPicker__swatchVertical__X7dAj {
//   position: relative;
//   text-align: center;
//   -webkit-box-flex: 1;
//   flex: 1;
//   margin-left: -30px; 
// }


// .ui.toggle.checkbox input:checked~label:before {
//   background-color: #37955D!important;
//   height: 20px !important;
//   width: 40px !important;
// }

// .ui.toggle.checkbox input:checked~label:after{
//   // background-color: #37955D!important;
//   left: 20.12px;
//   width: 17px;
//   top: 1.25px;
//   height: 17px;
// }


// .ui.toggle.checkbox label:before {
//   display: block;
//   position: absolute;
//   content: '';
//   z-index: 1;
//   -webkit-transform: none;
//   transform: none;
//   border: none;
//   top: 0;
//   background: rgba(0,0,0,.05);
//   -webkit-box-shadow: none;
//   box-shadow: none;
//   height: 20px !important;
//   width: 40px !important;
//   border-radius: 500rem;
// }


// .ui.toggle.checkbox input~label:after {
//   left: 3.25px;
//   width: 17px;
//   top: 1.25px;
//   height: 17px;
// }

}