.textHeader {
  flex: 1;
  font-size: 20px;
  cursor: pointer;
}

.full_loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}