@import "../variables.scss";
.logo {
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  text-decoration: none;

  .send {
    color: $gray-light;
  }

  .tric {
    color: $blue;
  }
}
