@import "../variables.scss";
.heading1{
    font-size:32px;
    line-height:38.4px;
    color: #000000;
}
.heading2{        
    font-size: 28px;
    line-height: 34px;
    color: #000000;
}
.heading3{        
    font-size: 24px !important;
    line-height: 28px !important;
    color: #000000;
}
.heading4{        
    font-size: 16px;
    line-height: 19.2px;
    color: #000000;
}
.nav_text{
    font-size: 18px;
    line-height: 21.6px;
    color: #000000;
}
.medium_text{
    font-size: 16px;
    line-height: 19.2px;
    color: #000000;
}
.small_body_text{
    font-size: 14px;
    line-height: 16.8px;
    color: #000000;
}
.ui.tabular.menu .active.item {
    border: none;
    border-bottom: 2px solid #4E84A1;
  }
  td.tables__actions__8BIZQ i {
    color: #4E84A1;
  }

  .accordionDiv{
    display:flex;
    justify-content:space-between;
    border-bottom:1px solid $grey-second;
  }

  .heading{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: $black-color;
  }

  .headingLevel1{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: $black-color;
  }
  
  .settingText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $grey-third;
  }

  .headingLevel2{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $black-color;
  }

  .boldText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $black-color;
  }

  .lightText{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $grey-third;
  }
