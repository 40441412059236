@import '../variables.scss';

.planBadge {
  background: $blue;
}

.planWarningBadge {
  background: $red-warning;
}


/* Add padding to the badge */
.badge {
  font-size: 0.8em;
  padding: 4px 4px;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #333;
  cursor: default;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  /* Add spacing between the badge and the text */
  margin-right: 3px; /* Adjust margin as needed */
}

.planWarningBadge {
  background-color: #ffcc00;
  color: #333;
  font-weight: bold;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  margin-right: 5px;
}
