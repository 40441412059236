body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.blue.progress .bar {
  background-color: #2185d0;
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 95%, 0.3) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 95%, 0.3) 0,
    hsla(0, 0%, 95%, 0.3) 75%,
    transparent 0,
    transparent
  );
  background-size: 40px 40px;
  -webkit-animation: f 2s linear infinite;
  animation: f 2s linear infinite;
}

@-webkit-keyframes f {
  0% {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes f {
  0% {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}
