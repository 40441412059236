@import "../variables.scss";
.firstRow {
  display: flex;
  justify-content: space-between;
}
.firstRowColumn {
  display: flex;
  gap: 10px;
  align-items: center;
}
.uploadFontStyle {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  color: #222222;
}
.statStyle {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #606062;
}
.uploadFontButton {
  background-color: $white !important;
  border: 1px solid $grey-second !important;
  border-radius: 7px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.firstRowUploadBtn {
  display: flex !important;
  align-items: center;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  gap: 8px;
  background: #ffffff !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  color: #222222 !important;
  border: 1.5px solid #bfbfbf !important;
}

.secondRow {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.secondRowTitle {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}
.secondRowDescription {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #606062;
}
.secondRowUploadBtn {
  display: flex !important;
  align-items: center;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  gap: 8px;
  background: #5dc2fa !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  color: #ffffff !important;
}

.thirdRow {
  display: flex;
  flex-wrap: wrap;
}

.thirdRowColumn {
  display: flex;
  flex: 0 0 calc(20% - 10px);
  margin: 5px 5px 5px 0px;
  padding: 10px 10px 10px 0px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 5px;
}
.thirdRowColumnHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
  background: #f7f8f8;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #606062;
}
.thirdRowColumnDataRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:8px;
  padding:8px;
  background: #F7F8F8;
}
.thirdRowColumnFontName {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  color: #000000;
}
.thirdRowColumnFontPath {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #606062;
}
.thirdRowColumnDataRowColumn1 {
  display: flex;
  gap: 3px;
  flex-direction: column;

}
.thirdRowThreeDot {
  cursor: pointer;
  position: relative;
}
.thirdRowMenuOptions {
  position: absolute;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #dce2e3;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #000000;
  width: 100px;
  bottom: 10px;
  left: -100px;
  z-index: 10;
  background: #ffffff;
}
.thirdRowMenuText {
  padding-bottom: 5px;
  border-bottom: 1px solid #dce2e3;
  margin-bottom: 5px;
}

.modalPopup {
  padding: 10px;
}
.modalFirstRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #dce2e3;
}
.modalFirstTitle {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000000;
}
.modalSecondRow {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0 10px 0;
}
.modalSecondText1 {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #606062;
  text-align: center;
}
.modalSecondText2 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #606062;
}
.modalChooseFontsBtn {
  display: flex !important;
  align-items: center;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  gap: 8px;
  background: #5dc2fa !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  color: #ffffff !important;
}
.modalThirdRow {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
}
.progressCol {
  margin-top: 10px;
}

.uploadText {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #606062;
  padding-bottom: 10px;
  border-bottom: 1px solid #dce2e3;
}
.fileText {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #606062;
}
.modalCancelRow {
  display: flex;
  justify-content: flex-end;
}
.modalCancelBtn {
  display: flex;
  padding: 8px 12px 8px 12px !important;
  border-radius: 6px !important;
  gap: 8px !important;
  background: #5dc2fa !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  color: #ffffff !important;
}

.inputText {
  border: 1px solid #5dc2fa;
  border-radius: 3px;
  padding: 5px;
}
.inputText:focus {
  outline: none;
}

.fourthRow {
  // margin-top: 20px;
  padding-top:20px;
}
.fourthRowHeading {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #222222;
}
.fourthRowHeadingDesc {
  padding-top: 5px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #606062;
}
.fifthRow {
  display: flex;
  margin-top: 15px;
  gap: 20px;
}
.fifthRowColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.fifthDropdownLabel {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  color: #222222;
}
.customDropdown i {
  cursor: pointer;
  padding-top: 4px !important;
}

.customDropdown {
  border: 1px solid #bfbfbf;
  border-radius: 4px !important;
  padding:10px !important;  
}

 

.customDropdownMenu {
  width:100% !important;
}
.customDropdownMenuHeader {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  color: #000000 !important;
  text-transform: none !important;  
}
.customDropdownMenuItem {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  color: #000000 !important;
  text-indent: 10px !important;
}
.sixthRow{
  margin-top: 20px;
  padding-bottom: 20px;  
}

.option{
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #000000;  
}


