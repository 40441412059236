@import "../variables.scss";
.emphasize {
  color: $blue;
}

.freeForm {
  display: flex;
  justify-content: center;
  padding: 20px 20px 60px 20px;
}

.timerInput {
  font-family: Lato;
  text-align: left;
  flex: 0 0 450px;
  padding: 40px;
  background: $white;
  border-radius: 2px;
}

.timerPreview {
  flex: 0 0 500px;
  text-align: center;
  padding: 40px;
  background: $white;
}

.previewImage {
  user-select: none;
}

.templatePreview {
  position: relative;
  width: 470px;
  margin: 0 auto;
  user-select: none;
}

.templatePreviewBody {
  width: 188px;
  height: 162px;
  position: absolute;
  top: 59px;
  left: 73px;

  img {
    width: 75%;
    padding: 10px 0;
  }
}

.templatePreviewMobile {
  width: 72px;
  height: 99px;
  position: absolute;
  right: 22px;
  top: 151px;

  div {
    line-height: 1;
    margin-top: 5px;
    font-size: 9px;
  }

  img {
    padding: 10px 2px;
  }

  button {

  }
}

.templatePreviewInfo {
    font-size: 9px;
    padding: 0 10px;
    line-height: 1.3;
}

.templatePreviewButton {
  border: none;
}

.timerSteps {
  padding-top: 30px;

  ol {
    text-align: left;
  }
}

.timerCodeContainer {
  img {
    margin: 10px 0;
  }
}

.timerCode {
  overflow: hidden !important;
  resize: none !important;
  border-radius: 2px !important;
  width: 500px !important;
  height:100px !important;
}

.colorPickers {
  display: flex;
  margin-top: 15px;
}

.generateButton {
  background: $blue !important;
  font-weight: 500 !important;
}

.tosCheckbox {
  padding: 10px 0;
}

.contact {
  color: $white !important; //override home layout styles

  a {
    color: $blue !important; //override home layout styles
  }
}

.featureDivider {
  margin: 60px 0 !important;
}

// mobile
@media only screen and (max-width: 768px) {
  .freeForm {
    display: block;
  }

  .templatePreview {
    zoom: 0.5;
    -moz-transform: scale(0.5);
  }
}