@mixin fluid-content {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  @media (min-width: 1200px) {
      max-width: 1200px;
  }

  // @media (max-width: 1199px) and (min-width: 992px) {
  //   max-width: 960px;
  // }
}