@import "../variables.scss";
.swatchVertical, .swatchHorizontal {
    .swatch {
      padding: 2px !important;
      background: $white;
      border-radius: 1px;
      box-shadow: 0 0 0 1px rgba($black, 0.1);
      display: inline-block;
      cursor: pointer;
    }
  }
  
  .swatchVertical {
    position: relative;
    display: inline-block;
    text-align: center;
    flex: 1;
  
    .color {
      border: 1px solid $gray-light;
      width: 34px !important;
      height: 34px !important;
    }
  
    label {
      position: relative;
      top: 12px;
      font-weight: bold;
      font-size: 14px;
      font-family: 'LATO';
      line-height: 16.8px;
    }

  
  }
  
  .swatchHorizontal {
    .color {
      width: 36px;
      height: 14px;
      border-radius: 2px;
    }
  }
  
  .popover {
    position: absolute;
    z-index: 10;
  }
  
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    pointer-events: none; /* Add this line */
  }
  
  .alignRight {
    left: -52px !important;
  }
  
  .alignCenter {
    left: -2px !important;
  }
  
  .alignTop {
    bottom: 80px;
  }


  

