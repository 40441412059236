.layout {
  flex: 1
}

.content {
  // @include fluid-content;
  margin: 30px auto 0 auto;
  width:80%;
}

.contentFullWidth {
  position: absolute;
  top: 65px;
  width: 100%;
  height: calc(100% - 65px - 92px); // subtract nav and footer
}
