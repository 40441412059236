@import "../variables.scss";

.gridColumn{    
    padding:16px 12px;
}
.gridTimerContainer{
    border: 1px solid rgba(220, 226, 227, 0.67);
    border-radius: 5px;
}
.gridTimerDetails{
    padding:12.5px 12px;
}
.gridImageContainer{
    display: flex;
    height:128px;
    background-color: #F5F5F5;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(220, 226, 227, 0.67);
}
.gridHeadingAndActionRow{
    display:flex;
    justify-content: space-between;
}
.gridTimerHeading{
    font-family: 'Lato';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.gridCreatedDateRow{
    margin-top:8px;
    display:flex;    
}
.gridUpdatedDateRow{
    display:flex;
}
.gridTextStyle{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px !important;
    line-height: 12px;
    color: #000000;
}
.gridSortBox{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
    
}

.gridSearchBox{
    display:flex;
    margin-top: 25px;
}
.gridIcons{
    display: flex;
    gap:5px;
}
.gridSearchText > input{
    width:350px;
}

.gridActions{
    display:flex;
    border-top: 1px solid rgba(220, 226, 227, 0.3);    
    padding:8px 15px 10px 15px;
    justify-content: space-between;
}

.actions{
    display: flex;
    align-items: center ;
    gap:13.5px;
}
.useActions{
    
}
.handPointer{
    cursor: pointer;
}