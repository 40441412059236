@import "../variables.scss";
.swatchVertical, .swatchHorizontal {
  .swatch {
    padding: 5px;
    background: $white;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba($black, 0.1);
    display: inline-block;
    cursor: pointer;
  }
}

.swatchVertical {
  position: relative;
  display: inline-block;
  flex: 1;

  .color {
    border: 1px solid $gray-light;
    width: 34px !important;
    height: 34px !important;
  }

  // label {
  //   display: block !important; //override bootstrap for home page
  // }
}

.swatchHorizontal {
  .color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }
}

.popover {
  position: absolute;
  z-index: 10;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  pointer-events: none; /* Add this line */
}

.alignRight {
  right: 50px !important;
}

.alignCenter {
  left: -2px !important;
}

.alignTop {
  bottom: 80px !important;
}